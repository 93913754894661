export const getPortfolioItems = () => {
    return {
        projects: [
            {
                movie: "jarvis",
                coverTitle: "images/jarvis-title.png",
                coverImg: "images/jarvis.png",
                infoDetail: "December 2022",
                infoDetailAccent: "Live Site",
                infoDetailLink: "",
                summaryItems: [ "Allows users to create Facebook and Google Ads in a simplified way that is managed by digital marketing experts.",
                          "The site manages Users, User Properties, User Ads, and accepts payment for individual ads."],
                addedInfos: [
                    {
                        title: "Tech Stack:",
                        info: "Typescript, AWS, Next.js, React, FastAPI, Python"
                    },
                    {
                        title: "Built to:",
                        info: "Help a client automate their business"
                    }
                ],
            },
            {
                movie: "nba",
                coverTitle: "images/nba-trade-rumor.png",
                coverImg: "images/nbatraderumor.png",
                infoDetail: "October 2022",
                infoDetailAccent: "Demo Site",
                infoDetailLink: "https://nba-trade-demo-app-7ei4g.ondigitalocean.app",
                summaryItems: ["Find the trades that could improve your team. Use up to date player and team data to find the best trades. The trade algorithm follows the current CBA laws to ensure your results are accurate."],
                addedInfos: [
                    {
                        title: "Tech Stack:",
                        info: "Typescript, Digital Ocean, Next.js, React"
                    },
                    {
                        title: "Built to:",
                        info: "Aid a Client in starting a business"
                    }
                ],
            },
            {
                movie: "searchforspeed",
                coverTitle: "images/search-for-speed.png",
                coverImg: "images/searchforspeed.png",
                infoDetail: "January 2022",
                infoDetailAccent: "Demo Site",
                infoDetailLink: "https://search-for-speed-demo-5opch.ondigitalocean.app/",
                summaryItems: ["Find the fastest cars in your area. This web application searches several car retailers for cars that are for sale in your area, but most importantly it orders these cars by their 0 to 60 times. Allowing you to find the fastest cars that you can buy on the used market."],
                addedInfos: [
                    {
                        title: "Tech Stack:",
                        info: "Javascript, AWS, Docker, Express, Puppeteer, React"
                    },
                    {
                        title: "Built to:",
                        info: "Start a business"
                    }
                ],
            },
        ],
        jobs: [
            {
                movie: "aptys",
                coverTitle: "images/aptys-title.png",
                coverImg: "images/aptyssolutions.png",
                infoDetail: "October 2021 - Present",
                infoDetailAccent: "Company Site",
                infoDetailLink: "https://aptyssolutions.com",
                summaryItems: ["Implemented PCI Compliance plan bringing Aptys from partial coverage to full PCI compliance by writing a set of AWS Config rules to scan AWS resources for PCI requirements.",
                          "Designed and implemented automated security reports, decreasing time spent running security checks from 3 days to less than 2 hours.",
                          "Increased security of prod deploys by creating a notification software that tracked credentials and alerted the appropriate personnel when changes occurred."],
                addedInfos: [
                    {
                        title: "Position:",
                        info: "Software Engineer"
                    },
                    {
                        title: "Tech Stack:",
                        info: "AWS, Angular, C#, Azure Pipelines"
                    }
                ],
            },
            {
                movie: "weaver",
                coverTitle: "images/weaver-title.png",
                coverImg: "images/weaver.png",
                infoDetail: "September 2020 - October 2021",
                infoDetailAccent: "Company Site",
                infoDetailLink: "https://weaverparts.com",
                summaryItems: ["Built a user emulation software that reduces dispatching from 35 weekly hours to less than 5.",
                          "Built AutoMVR, a software that automates the MVR process to reduce weekly hours in pricing from 80 hours to 25.",
                          "Wrote scripts to automate sales data entry, which reduced the time spent on data entry from 3 weeks to 1 hour."],
                addedInfos: [
                    {
                        title: "Position:",
                        info: "Process Automation/Data Analyst"
                    },
                    {
                        title: "Tech Stack:",
                        info: "C#, Python, AWS, R"
                    }
                ],
            },
            {
                movie: "siervocomp",
                coverTitle: "images/siervocomp-title.png",
                coverImg: "images/siervocomp.png",
                infoDetail: "June 2018 - July 2020",
                infoDetailAccent: "Company Site",
                infoDetailLink: "https://siervocomp.com",
                summaryItems: ["Installed, configured, and supported computer systems, software, and networks.",
                          "Resolved hardware and software problems and optimized system performance.",
                          "Planned and executed system upgrades and maintenance."],
                addedInfos: [
                    {
                        title: "Position:",
                        info: "IT Technician"
                    }
                ],
            }
        ],
        other: [
            {
                movie: "tfc",
                coverTitle: "images/tfc-title.png",
                coverImg: "images/tfc.png",
                infoDetail: "2021",
                infoDetailAccent: "College Site",
                infoDetailLink: "https://tfc.edu",
                summaryItems: [],
                addedInfos: [
                    {
                        title: "Major:",
                        info: "Philosophy"
                    },
                    {
                        title: "GPA:",
                        info: "3.5"
                    }
                ],
            },
            {
                movie: "skill",
                coverTitle: "images/skills-title.png",
                coverImg: "images/skills.png",
                infoDetail: "",
                infoDetailAccent: "Linked In",
                infoDetailLink: "https://www.linkedin.com/in/jonathan-joransen-19975a190/",
                summaryItems: [],
                addedInfos: [
                    {
                        title: "Use Daily:",
                        info: "C#, Typescript/Javascript, AWS, React, Next.js, SQL, Azure Pipelines"
                    },
                    {
                        title: "Use Regularly:",
                        info: "Python, Angular, Docker, Java"
                    }
                ],
            }
        ],
    }
}
