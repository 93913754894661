import React, { useEffect } from "react";
import styles from "../styles/PortfolioItemInfo.module.css";
import CoverVideo from "./CoverVideo.jsx";
import { useSearchParams } from "react-router-dom";

const PortfolioItemInfo = ({open, setOpen, item}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(open){
      if (searchParams.get("id") != item.movie) { 
        setSearchParams({id: item.movie})
      }
      document.body.style.overflow = 'hidden';  
    } else {
      if (searchParams.get("id") == item.movie) {
        setSearchParams()
      }
      document.body.style.overflow = 'auto';  
    }
  }, [open])
  
  return (
    <>
      {open && (
        <div className={styles.container} >
          <div className={styles.focusWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.imgContainer}>
              <CoverVideo movieId={item.movie} titleSrc={item.coverTitle} posterSrc={item.coverImg} subTitle={item.subTitle}></CoverVideo>
            </div>
            <div onClick={onClick} className={styles.close}>&#10005;</div>
            <div className={styles.infoContainer}>
              <div className={styles.detailsContainer}>
                <div className={styles.infoDetails}>
                  {
                      item?.infoDetailLink !== "" &&
                      <span><a href={item.infoDetailLink} target="_blank">{item.infoDetailAccent}</a></span>
                  }
                  {item.infoDetail}
                </div>
                <ul className={styles.summary}>
                  {item.summaryItems.map((st, idx) => <li key={idx}>{st}</li>)}
                </ul>
              </div>
              <div className={styles.allAddedInfo}>
                {item.addedInfos.map((adi) => 
                  <div key={adi.title} className={styles.addedInfoContainer}>
                    <div className={styles.addedInfoTitle}>
                      {adi.title}
                    </div>
                    <div className={styles.addedInfo}>
                      {adi.info}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PortfolioItemInfo;
